@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  z-index: 4;
  right: 50%;
  top: 50%;
  position: fixed;
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #383636;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
@media only screen and (max-width:1024px) {
  .loading-spinner {
    z-index: 4;
    right: 40%;
    top: 50%;
    position: fixed;
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3;
    border-top: 10px solid #383636;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
}
@media only screen and (min-width: 540px) and (max-width: 1366px) {
  .loading-spinner {
    z-index: 4;
    right: 45%;
    top: 38%;
    position: fixed;
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3;
    border-top: 10px solid #383636;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
}