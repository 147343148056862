.container_center{
    text-align: center;
    vertical-align: center;
}
.space_top{
  padding-top: 2%;
}
.label{
    display: block;
    display:inline-block;
}
.vertical_align{
  padding-top: 20vh;
}
.button{
    height: 35px;
    background-color: #A92F51;
    color: white;
    border: 2px solid rgb(223, 209, 209);
    border-radius: 5px;
    font-weight: 700;
    cursor: pointer;
}
.input{
  height: 35px;
  width: 40%;
  border: 2px solid rgb(223, 209, 209);
  border-radius: 5px;
  font-size: 16px;
  padding-left: 1%;
}
@media only screen and (min-width: 1424px) {
  .button{
    height: 35px;
    width: 22%;
    background-color: #A92F51;
    color: white;
    border: 2px solid rgb(223, 209, 209);
    border-radius: 5px;
    font-weight: 700;
    cursor: pointer;
}
}
