.card{
    padding: 10px;
}
.card_border{
    padding: 5px 0px 1px 10px;
    border: 2px solid rgb(245, 242, 242);
    background-color: rgb(241, 241, 241);
    border-radius: 5px;
}
.border{
    border: 2px solid rgb(208, 200, 200);
    margin: 0 1% 1% 0;
}
.sub_bg{
    background-color: white;
}
.pointer{
    cursor: pointer;
}
.uploadFile{
    display: none;
}
.upload_button{
    height: 40px;
    width: 20vh;
    background-color: #A92F51;
    color: white;
    border: 2px solid rgb(223, 209, 209);
    border-radius: 5px;
    font-weight: 700;
    cursor: pointer;
}
.error{
    color: #bf2551;
}
.label{
    display: inline-block;
    background-color: white;
    border: 2px solid rgb(219, 216, 216);
    color: black;
    padding: 0.5rem;
    font-family: sans-serif;
    border-radius: 0.3rem;
    cursor: pointer;
    text-align: center;
  }
  .required:after {
    content:" *";
    color: red;
  }
  label{
      font-weight: 500;
  }
.input{
    height: 5vh;
    width: 45vh;
    border: 2px solid rgb(223, 209, 209);
    border-radius: 5px;
    font-size: 16px;
    padding-left: 1%;
}
.description{
    border-radius: 5px;
    border: 2px solid rgb(223, 209, 209);
    height: 5vh;
    width: 45vh;
    resize: none;
    font-size: 16px;
    padding-left: 1%;
}
.address{
    border-radius: 5px;
    border: 2px solid rgb(223, 209, 209);
    height: 8vh;
    width: 45vh;
    font-size: 16px;
    padding-left: 1%;
}
.input_large{
    height: 6vh;
    width: 100%;
    border: 2px solid rgb(223, 209, 209);
    border-radius: 5px;
    font-size: 16px;
    padding-left: 1%;
    -webkit-appearance: none;
    resize: none;
}
.soclia_input{
    height: 6vh;
    width: 100%;
    border: 2px solid rgb(223, 209, 209);
    border-radius: 5px;
    font-size: 16px;
    padding-left: 1%;
    -webkit-appearance: none;
    resize: none;
}
.checkbox{
    height: 22px;
    width: 22px;
}
.title{
    padding: 15px 0 0 0;
    justify-content: center;
}
.checkbox_div{
    display: flex;
    padding: 3% 0 0 5px;
}
.feature_checkbox_label{
    width: 30%;
}
.checkbox_label{
    padding: 5px 0 0 10px;
}
.row{
    display: flex;
}
.row_padding{
    display: flex;
    padding-bottom: 4%;
}
.submit_preview {
    height: 40px;
    width: 100%;
}
.mobile_row{
    display: flex;
    padding: 2% 0 0 2%;
}
.row_div{
    display: flex;
    padding: 2% 0 0 0;
}
.mobile_row_space{
    display: flex;
    padding: 2% 2% 0 0;
}
.mobile_center{
   padding: 3% 0 0 1%; 
}
.center_sec{
    display: flex;
}
.center_header{
    display: flex;
    padding: 0 10px 0 10px;
    justify-content: space-between;
}
.center_header_padding{
    padding-top: 10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@media only screen and (max-width:1300px) {
    .mobile_row{
        display:grid;
        grid-template-columns: auto;
    }
    .row_div{
        display:grid;
        grid-template-columns: auto;
        justify-content: start;
    }
    .mobile_row_space{
        display:grid;
        grid-template-columns: auto;justify-content: center;
    }
    .center_sec{
        display:grid;
        grid-template-columns: auto;justify-content: center;
    }
    .mobile_padding_top{
        padding-top: 10%;
    }
    .input{
        height: 30px;
        width: 40vh;
        border: 2px solid rgb(223, 209, 209);
        border-radius: 5px;
    }
    .description{
        border-radius: 5px;
        border: 2px solid rgb(223, 209, 209);
        height: 30px;
        width: 40vh;
    }
    .address{
        border-radius: 5px;
        border: 2px solid rgb(223, 209, 209);
        height: 8vh;
        width: 40vh;
    }
    .mobile_center{
        text-align: center;
    }
    .feature_checkbox_label{
        width: 70%;
    }
    .row_padding{
        display: flex;
        padding-bottom: 0%;
    }
  
}
.padding_left{
    padding-left: 2%;
}
.padding_left_sec_two{
    padding-left: 1%;
}
.padding_top{
    padding-top: 4%;
}
.padding_tb_rl{
    padding: 1% 0;
}
.margin_l{
    margin-left: 1%;
}
.padding_bottom{
    padding-bottom: 4%;
}
.space_between{
    justify-content: space-around;
}
.center{
    justify-content: center;
}
.center_div{
    text-align: center;
   
}
.start_div{
    text-align: start;
    padding-left: 100%;
}
.add_button_center{
    text-align: center;
    justify-content: center;
}
.cengter_div{
    text-align: center;
    align-items: center;
    justify-content: center;
}
.footer_button{
text-align: center;
justify-content: center;
}

.logo_header{
    border: 1px solid black;
    background-color: white;
    height: 150px;
    width: 150px;
    border-radius: 20px;
}
.logo_border{
    height: 150px;
    width: 150px;
    border-radius: 20px;
}
.banner_header{
    border: 1px solid rgb(224, 222, 222);
    background-color: white;
    height: 150px;
    width: 45vh;
    border-radius: 20px;
}
.banner_image{
    height: 150px;
    width: 45vh;
    border-radius: 20px;
}

@media only screen and (max-width:800px) {
    .card{
        padding: 10px;
        width: 100%;   
    }
    .upload{
        padding: 5% 0 0 13%;
        height: 40px;
        width: 100%;
    }
}

.listImg{
    height: 50px;
    width: 50px;
}
.added_list_width{
    width: 100%;
}
.add_button{
    padding-top: 3%;
}
.added_list{
    border: 2px solid rgb(223, 209, 209);
    border-radius: 10px;
    justify-content: center;
    width: 100%;
    justify-content: space-between;
 }
.added_image{
    padding: 4% 0 0 10px;
}
.added_content{
    padding: 0 0 0 15px;
}
.delete{
    padding:  0 13px 0 17px;
    text-align: center;
    margin: 0px;
}
.delete_container{
    height: 30px;
    width: 30px;
    background-color: rgb(15, 14, 14);
    border-radius: 6px;
    cursor: pointer;
}
.cross{
    padding-top: 4px;
    color: white;
}
li{
    list-style: none;
    width: 90%;
    padding: 10px 0 0 0;
}
.header_space{
    justify-content: space-between;
    padding: 10px 20px 15px 0;
}
.row_space{
    justify-content: space-between;
    padding: 10px 20px 9px 0;
}
.space_left{
    padding-left: 1%;
}
.image_label{
    width: 45%;
}

.image_label_plan_room{
    width: 60%;
}
@media screen and (max-width: 900px) and (min-width: 300px) {
  .added_list_width{
    width: 100%;
    padding: 0 4%;
}

  }
.title_url{
    padding: 15px 5% 0 0px;
    justify-content: center;
}

.dev_image{
    justify-content: center;
}
.toggle_off{
    background-color: #A92F51;
    height: 2.8vh;
    width: 5vh;
    border-radius: 10px;
    cursor: pointer;
}
.toggle_on{
    background-color: #597d28;
    height: 2.8vh;
    width: 5vh;
    border-radius: 10px;
    cursor: pointer;
}
.toggle_left{
    margin: 3px;
    background-color: white;
    height: 2vh;
    width: 2vh;
    border-radius: 10px;
}
.toggle_right{
    margin: 3px 3px 2px 2.5vh;
    background-color: white;
    height: 2vh;
    width: 2vh;
    border-radius: 10px;
}
.p{
    color: #9D9D9D;
    font-size: 13px;
}
.pl{
    padding: 30px 0 0 20px;
}
.display_flex{
    display: flex;
}
.social_spacing{
    padding: 2% 1% 0 0;
    height: 50px;
    width: 50px;
}
.footer_fields{
    height: 5vh;
    width: 30vh;
    border: 2px solid rgb(223, 209, 209);
    border-radius: 5px;
    font-size: 16px;
    padding-left: 1%;
}
.footer_fields_link{
    height: 5vh;
    width: 50vh;
    border: 2px solid rgb(223, 209, 209);
    border-radius: 5px;
    font-size: 16px;
    padding-left: 1%;
}
.footer_address{
    height: 10vh;
    width: 45vh;
    resize: none;
    border: 2px solid rgb(223, 209, 209);
    border-radius: 5px;
    font-size: 16px;
    padding-left: 1%;
}
.margin_top{
    margin-top: 3%;
}
@media only screen and (max-width:1200px) {
    .footer_fields_link{
        height: 5vh;
        width: 33vh;
        border: 2px solid rgb(223, 209, 209);
        border-radius: 5px;
        font-size: 16px;
        padding-left: 1%;
    }
    .image_label{
        width: 100%;
    }
    .pl{
        padding: 20px 0 0 0;
    }
    .upload_button{
        height: 40px;
        width: 15vh;
        background-color: #A92F51;
        color: white;
        border: 2px solid rgb(223, 209, 209);
        border-radius: 5px;
        font-weight: 700;
        cursor: pointer;
    }
}


.input_padding_left{
    padding-left: 18%;
}
.footer_input_padding_left{
    padding-left: 12%;
}
 @media only screen and (max-width:900px) {
    .list_style {
        list-style: none;
        width: 100%;
        padding: 10px 0 0 0;
    }
    .border_div{
        width: 100%;
    }
    .width{
        width: 60%;
    }
    .input_padding_left{
        padding-left: 0%;
    }
    .footer_input_padding_left{
        padding-left: 0%;
    }
    .banner_header{
        border: 1px solid rgb(224, 222, 222);
        background-color: white;
        height: 150px;
        width: 35vh;
        border-radius: 20px;
    }
    .banner_image{
        height: 150px;
        width: 35vh;
        border-radius: 20px;
    }
    .footer_fields{
        height: 5vh;
        width: 30vh;
        border: 2px solid rgb(223, 209, 209);
        border-radius: 5px;
        font-size: 16px;
        padding-left: 1%;
    }
}
@media only screen and (max-width:1366px) {
    .banner_image{
        height: 150px;
        width: 30vh;
        border-radius: 20px;
    }
    .banner_header{
        border: 1px solid rgb(224, 222, 222);
        background-color: white;
        height: 150px;
        width: 30vh;
        border-radius: 20px;
    }
    .footer_fields{
        height: 5vh;
        width: 30vh;
        border: 2px solid rgb(223, 209, 209);
        border-radius: 5px;
        font-size: 16px;
        padding-left: 1%;
    }
    .toggle_off{
        background-color: #A92F51;
        height: 2.2vh;
        width: 4vh;
        border-radius: 10px;
        cursor: pointer;
    }
    .toggle_on{
        background-color: #597d28;
        height: 2.2vh;
        width: 4vh;
        border-radius: 10px;
        cursor: pointer;
    }
    .toggle_left{
        margin: 3px;
        background-color: white;
        height: 1.5vh;
        width: 1.5vh;
        border-radius: 10px;
    }
    .toggle_right{
        margin: 2.7px 3px 2px 2vh;
        background-color: white;
        height: 1.5vh;
        width: 1.5vh;
        border-radius: 10px;
    }
   
    .footer_address{
        height: 10vh;
        width: 100%;
        resize: none;
        border: 2px solid rgb(223, 209, 209);
        border-radius: 5px;
        font-size: 16px;
        padding-left: 1%;
    }
    .social_spacing{
        padding: 4% 2% 0 0;
    }
    .soclia_input{
        width: 29vh;
        border: 2px solid rgb(223, 209, 209);
        border-radius: 5px;
        font-size: 16px;
        padding-left: 1%;
        -webkit-appearance: none;
        resize: none;
    }
}
@media only screen and (min-width: 200px) and (max-width: 719px) {
    .social_spacing{
        padding: 5.5% 2% 0 0;
    }
}

.header{
    height: 60px;
    width: 100%;
    left: 0;
    text-align: center;
    border-bottom: 2px solid rgb(243, 236, 236);
    background-color: white;
    z-index: 2;
    overflow: hidden;
    position: fixed;
}
.portal_padding{
    padding-top: 6%;
}
@media only screen and (min-width: 200px) and (max-width: 812px) {
    .portal_padding{
        padding-top: 16%;
    }
    .soclia_input{
        width: 24.5vh;
        border: 2px solid rgb(223, 209, 209);
        border-radius: 5px;
        font-size: 16px;
        padding-left: 1%;
        -webkit-appearance: none;
        resize: none;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .portal_padding{
        padding-top: 13%;
    }
}
@media only screen and (min-width: 600px) and (max-width: 769px) {
    .portal_padding{
        padding-top: 13%;
    }
}
@media only screen and (min-width: 900px) and (max-width: 1024px) {
    .portal_padding{
        padding-top: 13%;
    }
}
@media only screen and (min-width: 1024px) and (max-width: 1366px) {
    .portal_padding{
        padding-top: 13%;
    }
    .soclia_input{
        width: 20vh;
        border: 2px solid rgb(223, 209, 209);
        border-radius: 5px;
        font-size: 16px;
        padding-left: 1%;
        -webkit-appearance: none;
        resize: none;
    }
}
.bn{
    height: 50px;
    width: 100%;
    border: 2px solid rgb(223, 209, 209);
    border-radius: 5px;
    font-size: 16px;
    padding-left: 1%;
}
.ad{
    height: 100px;
    width: 95%;
    border: 2px solid rgb(223, 209, 209);
    border-radius: 5px;
    font-size: 16px;
    padding-left: 1%;
    resize: none;
}
.sm{
    height: 50px;
    width: 100%;
    border: 2px solid rgb(223, 209, 209);
    border-radius: 5px;
    font-size: 16px;
    padding-left: 1%;
    display: flex;
    flex: 1;
    margin: 0!important;
}
.logout_style{
    border: 2px solid rgb(223, 209, 209);
    border-radius: 5px;
    background-color: white;
    padding-bottom: 5px;
}
.mouse_click{
    pointer-events: none;
}