
@media only screen and (max-width:800px) {
    li{
        list-style: none;
        width: 100%;
        padding: 10px 0 0 0;
        margin-left: -15%;
    }
}
.text_editor_width{
    width: 100%;
}
.add_alignment{
    text-align: start;
    padding: 1% 1% 1% 1%;
}
.testimonial_alignment{
    padding-left: 1%;
    display: flex;
}
@media only screen and (max-width:1200px) {
.button{
    height: 40px;
    width: 21vh;
    background-color: #A92F51;
    color: white;
    border: 2px solid rgb(223, 209, 209);
    border-radius: 5px;
    font-weight: 700;
    cursor: pointer;
}
}
.remove_padding{
    padding: 0% 1% 0 2%;
}
.add_button{
    height: 40px;
    width:15vh;
    background-color: #A92F51;
    color: white;
    border: 2px solid rgb(223, 209, 209);
    border-radius: 5px;
    font-weight: 700;
    cursor: pointer;
}
.button{
    height: 40px;
    width:100%;
    background-color: #A92F51;
    color: white;
    border: 2px solid rgb(223, 209, 209);
    border-radius: 5px;
    font-weight: 700;
    cursor: pointer;
}