.spacing{
    padding: 18px 0 0 20px;
}
.submenu_space{
    padding: 17px 10px 0 0;
}
.sublink{
    padding-left: 10px;
}
.submenu_title{
    padding-left:20px ;
}
.submenu_border{
    border: 2px solid rgb(22, 20, 20);
    background-color: rgb(241, 241, 241);
    border-radius: 5px;
    
}
.border{
    border: 2px solid rgb(208, 200, 200);
    margin: 0 1% 1% 0;
}
.margin_bottom{
    margin-bottom: 2%;
}
.margin_left{
    margin-left: 2%;
}
.add_button{
    text-align: end;
}
.footer_fields{
    height: 5vh;
    width: 30vh;
    border: 2px solid rgb(223, 209, 209);
    border-radius: 5px;
    font-size: 16px;
    padding-left: 1%;
}
.row_space{
    justify-content: space-between;
    padding: 10px 0px 9px 4.5%;
}

.align_right{
    justify-content: flex-end;
}
.menu_width{
    width: 100%;
}
@media only screen and (max-width: 1800px) {
        .menu_width{
            width: 80%;
        }
}
.menu_input{
    height: 50px;
    width: 100%;
    border: 2px solid rgb(223, 209, 209);
    border-radius: 5px;
    font-size: 16px;
    padding-left: 1%;
}
.menu_size{
    width: 100%;
}
.add_menu{
    height: 40px;
    width:15vh;
    background-color: #A92F51;
    color: white;
    border: 2px solid rgb(223, 209, 209);
    border-radius: 5px;
    font-weight: 700;
    cursor: pointer;
}
.row{
    display: flex;
}
.pl{
    padding-left: 1%;
}
.remove_btn{
    padding: 26px 0 0 5px;
} 
.remove_width{
    width: 100%;
}
.disable_button{
    height: 40px;
    background-color: #cb889b;
    color: white;
    border: 2px solid rgb(223, 209, 209);
    border-radius: 5px;
    font-weight: 700;
    cursor: pointer;
}
@media only screen and (max-width:1000px) {
    .row{
        display:grid;
        grid-template-columns: auto;justify-content: center;
    }
    .pl{
        padding-left: 0%;
    }
    .menu_input{
    height: 50px;
    width: 35vh;
    border: 2px solid rgb(223, 209, 209);
    border-radius: 5px;
    font-size: 16px;
    padding-left: 1%;
}
.remove_btn{
    padding: 10px 0 0 0;
} 
.remove_width{
    width: 50%;
}
}