.red{
    background-color: red;
    height: 40px;
    width: 40px;
}
.focus_border{
    border-radius: 5px;
    padding: 4px;
}
.theme_color{
    margin-left: 5%;
}
.choosed_color{
    border: 2px solid black;
    border-radius: 5px;
    padding: 2px;
}
.choosed_transperant{
    border: 2px solid rgba(0, 0, 0, 0);
    border-radius: 5px;
    padding: 2px;
}

.focus_border:focus{
    border: 2px solid black;
    border-radius: 5px;
    padding: 4px;
}
.orange{
    border-radius: 5px;
    height: 42px;
    width: 42px;
    background-color: #d05a18;
}
.green{
    border-radius: 5px;
    height: 42px;
    width: 42px;
    background-color: #009a61;
}
.blue{
    position: relative;
    border-radius: 5px;
    height: 42px;
    width: 42px;
    background-color: #20639b;
}
.violet{
    border-radius: 5px;
    height: 42px;
    width: 42px;
    background-color: #6859a8;
}
.red{
    border-radius: 5px;
    height: 42px;
    width: 42px;
    background-color: #c2261f;
}
.grey{
    border-radius: 5px;
    height: 42px;
    width: 42px;
    background-color: #5b5b5b;
}
@media only screen and (max-width:1300px) {
.theme_color{
    margin-left: 1%;
}
}
@media screen and (min-width: 320px) and (max-width: 568px) {
    .orange{
        border-radius: 5px;
        height: 32px;
        width: 32px;
        background-color: #d05a18;
    }
    .green{
        border-radius: 5px;
        height: 32px;
        width: 32px;
        background-color: #009a61;
    }
    .blue{
        position: relative;
        border-radius: 5px;
        height: 32px;
        width: 32px;
        background-color: #20639b;
    }
    .violet{
        border-radius: 5px;
        height: 32px;
        width: 32px;
        background-color: #6859a8;
    }
    .red{
        border-radius: 5px;
        height: 32px;
        width: 32px;
        background-color: #c2261f;
    }
    .grey{
        border-radius: 5px;
        height: 32px;
        width: 32px;
        background-color: #5b5b5b;
    }
    }