.domain_padding{
    padding-bottom: 2%;
}
.padding_left{
    padding-left: 9px;
}
.pl{
    padding-left: 10px;
}
@media only screen and (min-width: 200px) and (max-width: 812px) {
    .pl{
        padding-left: 0px;
    }
    .padding_left{
        padding-left: 0px;
    }
}