.list_style {
    list-style: none;
    width: 50%;
    padding: 10px 0 0 0;
}
.added_list{
    border: 2px solid rgb(223, 209, 209);
    border-radius: 10px;
    justify-content: space-between;
 }
 .border{
    border: 2px solid rgb(208, 200, 200);
    margin: 0 1% 1% 0;
    padding: 20px;
}
.width{
    width: 60%;
}
.padding_left{
    padding:0 5%;
}
.input_padding_left{
    padding-left: 18%;
}
 @media only screen and (max-width:900px) {
    .list_style {
        list-style: none;
        width: 100%;
        padding: 10px 0 0 0;
    }
    .border_div{
        width: 100%;
    }
    .width{
        width: 60%;
    }
    .input_padding_left{
        padding-left: 0%;
    }
    .social_media{
        padding-left: 0px;
    }
}
.row{
    display: flex;
}
.row :last-child {
    width: 100%;
  }
  .row > * {
    flex: 0 auto;
  }